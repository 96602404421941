import React from 'react'
import "./GetInTouch.css"

import technologies from "./img/technologies.png"

export default function GetInTouch() {
  return (
    <div className="getInTouch-container" id="getInTouch">
      <div className="getInTouch__header">
        <div className="getInTouch__header__headline">
          <h1>
            Ready to take off?
          </h1>
        </div>
      </div>

      <div className="getInTouch__content">
        <div className="getInTouch__content__headline">
        </div>
        <div className="getInTouch__content__hireMeFor">
          <div>Interested in a collaboration? Get in touch, for example via <a href="https://www.linkedin.com/in/josé-benjamin-m-j-guerrero-vazquez-30369b14b">LinkedIn</a>.  </div>
          <div>I am open for any
            <strong> fulltime</strong>-, <strong>parttime</strong>-, <strong>remote</strong>-job from August 2020 on.</div>
        </div>

        <div className="getInTouch__content__footer">
          <div className="getInTouch__content__footer-left">
            <span>José Guerrero Vazquez</span>
            <span>jbmj.vazquez@gmail.com</span>
            <span><a href="https://www.linkedin.com/in/josé-benjamin-m-j-guerrero-vazquez-30369b14b">Linked <i class="fab fa-linkedin"></i></a></span>
            <span>017642 7879 53</span>
          </div>

          <div className="getInTouch__content__footer-right">
            <img src={technologies} alt="" />
          </div>
        </div>
      </div>

    </div>
  )
}