import React from 'react'
import './desktop.css'

import myAvatar from "./img/profilpic.png"
import myKanban from "./img/mykanban.gif"
import cirRund from "./img/cir_rund.png"
import handy from "./img/handy.png"
import apache from "./img/apache_cut.png"

export default function MyPortfolioDesktop(props) {

  const {skillsList} = props

  return (
    <div className="App">

      <header>
        <div className="logo">
        </div>
      </header>

      <section className="intro" id="home">
        <h1 className="section__title-desktop section__title--intro">
          <strong>Ready to take off?</strong>
        </h1>
        <p className="section__subtitle section__subtitle--intro">Frontend-Developer waiting to reinforce your team!</p>
        <p> </p>
        <p className="section_skillsList">{
          skillsList.map((skill, index) => {
            let skillLevel = skill.level*2 + 4 + Math.floor(Math.random() * Math.floor(45))
            let r = Math.floor(Math.random() * Math.floor(155))
            let g = Math.floor(Math.random() * Math.floor(155))
            let b = Math.floor(Math.random() * Math.floor(155))
            let randomColor = `rgb(${r},${g},${b})`
            return (
              <span key={index} style={{ fontSize: skillLevel, color: randomColor }}>{skill.skill} </span>
            )
          })
        }</p>
        <img src={apache} alt="" className="intro__img" />
      </section>


      <section className="my-services" id="services">
        <h2 className="section__title-desktop section__title--services">What I do</h2>
        <div className="services">
          <div className="service">
            <h3>Today</h3>
            <p>By now I am part of the Deveoper-Team at <strong>Kommando Cyber Innovation Room CIR</strong>, which is a pilot project to bring StartUp ideas and the Germany Army together.  </p>
            <p>Here i mainly do Developement with React JS and RESTful API in a Sharepoint enviroment. </p>
          </div>

          <div className="service">
            <h3>My Skills</h3>
            <p>I consider my professional strengths to lie in being eager to learn and broadminded.</p>
            <p> Even though i am having most fun solving logical problems with <strong>JavaScript</strong>, there are many other things sparking my interest.</p>
          </div>

          <div className="service">
            <h3>Future </h3>
            <p>
              I continously strive to build up my capabilities and to reach out for new goals. I am open minded to dive into new work experiences <strong style={{color: "#7bc17a"}} >from August 2020</strong>.
              I consider job offers including fulltime-, parttimejobs and remote.
              <br/><a href="#footer" className="cta_btn">Get in touch!</a>
            </p>
          </div>
        </div>
      </section>


      <section className="about-me" id="about">
        <h2 className="section__title-desktop section__title--about">I am</h2>
        <p className="section__subtitle section__subtitle--about">José Guerrero Vazquez, <span> Technical Assistent for Informatics</span></p>

        <div className="about-me__body">
          <p>
            After apprenticing as a Technical Assistent for Computer Science my first job was planing and programming database
            interfeaces with <strong>MySQLi</strong> and <strong>PHP</strong>  in a Web-Relaunch Projekt.
          </p>
          <p>I also gained experience managing a Webshop of a small retail store and as a technical consultant.</p>
          <p>Anyways, my very first carreer was a military. Since i remained engaged with the Bundeswehr through being a reservist, i was offered the opportunity to gain some valuable experience working at the Cyber Innovation Room from Oktober/2019 till August/2020.  </p>
          <br />
          A brief overview:
          <div className="curriculum">

            <div className="curriculum__entry">
              <div className="curriculum__entry__col-left">since August 2020</div>
              <div className="curriculum__entry__col-right">
                <span className="curriculum__entry__headline">
                  Junior Frontend Developer
                </span>
                <span className="curriculum__entry__company">
                  CHECK24 Hotelvergleich - www.hotel.check24.de
                </span>
                <span className="curriculum__entry__responsibilities">
                  Working in the frontend developer team, using React JS, TypeScript, Mobx, SASS, Twig, PHP, MVC, SCRUM, KanBan, Jira, Bitbucket
                </span>
              </div>
            </div>

            <div className="curriculum__entry">
              <div className="curriculum__entry__col-left">2019-2020</div>
              <div className="curriculum__entry__col-right">
                <span className="curriculum__entry__headline">
                  Web-Developer
                </span>
                <span className="curriculum__entry__company">
                  Kommando Cyber Innovation Room, Bonn
                </span>
                <span className="curriculum__entry__responsibilities">
                  Developing Data-Management Solutions for the Intranet of the Command using, inter alia, React JS, RESTful APIs, ES2015,..
                </span>
              </div>
            </div>

            <div className="curriculum__entry">
              <div className="curriculum__entry__col-left">2013-2019</div>
              <div className="curriculum__entry__col-right">
                <span className="curriculum__entry__headline">
                  Work Experience
                </span>
                <span className="curriculum__entry__company">
                  Retailing sector | UnityMedia | HAWK
                </span>
                <span className="curriculum__entry__responsibilities">
                  WebShop Administration, Technical Consultant, Database-Administration
                </span>
              </div>
            </div>

            <div className="curriculum__entry">
              <div className="curriculum__entry__col-left">2011-2013</div>
              <div className="curriculum__entry__col-right">
                <span className="curriculum__entry__headline">
                  Vocational Training
                </span>
                <span className="curriculum__entry__company">
                  Sabine Blindow Schulen, Hannover
                </span>
                <span className="curriculum__entry__responsibilities">
                  Training as Technical Assistent for Computer Science
                </span>
              </div>
            </div>

            <div className="curriculum__entry">
              <div className="curriculum__entry__col-left">2005-2010</div>
              <div className="curriculum__entry__col-right">
                <span className="curriculum__entry__headline">
                  Military Service
                </span>
                <span className="curriculum__entry__company">
                  German Army (Bundeswehr), JgBtl292 - Franco-German Brigade
                </span>
                <span className="curriculum__entry__responsibilities">
                  Infantryman
                </span>
              </div>
            </div>
          </div>

        </div>

        <img src={myAvatar} alt="" className="about-me__img" />
      </section>

      <section className="my-work" id="work">
        <h2 className="section__title-desktop projects section__title--work" >Recent Projects</h2>

        <div className="portfolio">

          <div className="portfolio__item">
            <span className="portfolio__item__desc">I am contributing significantly in building up a flexible intranet Dashboard solution for the CIR, which allows to aggregated and manage information from, coming different sources, in one place, according to a users identity and permissions.</span>
            <div className="about-me__img-container">
              <img src={cirRund} alt="Kommand Cyber Innovation Room" class="about-me__img" />
            </div>
          </div>

          <div class="portfolio__item">
            <span className="portfolio__item__desc">The mobile version of this website brings a bit of technical gimmicks with it. You can find your perfect developer my chosing skills you you looking for. Try it on your mobile phone or simple by setting the developer tools to the mobile phone emulator. </span>
            <div className="about-me__img-container">
              <img src={handy} alt="mobile version" class="about-me__img" />
            </div>
          </div>

          <div class="portfolio__item">
            <span className="portfolio__item__desc">Due to the Corona (Covid-19) issue i recently built a proptotype of a digital Kanban board to make it prossible for our Team to keep up the regular Kanban-Sessions. Including a firebare db makes it possible to update movement in realtime on different devices:</span>
            <div className="about-me__img-container">
              <img src={myKanban} alt="Digital KanBan Prototype" className="about-me__img" />
            </div>
          </div>

        </div>
      </section>


      <footer className="footer" id="footer">
        <p>Feel free to contact me!</p>
        <a href="mailto:jbmj@outlook.de" className="footer__link">jbmj.vazquez@gmail.com</a>
        <ul className="social-list">
          <li className="social-list__item">
            <a className="social-list__link" href="https://www.linkedin.com/in/josé-benjamin-m-j-guerrero-vazquez-30369b14b" target="new" >Linked
              <i className="fab fa-linkedin"></i>
            </a>
          </li>
        </ul>
      </footer>
    </div>
  )
}