import React, { useState } from 'react'

export default function MyPortfolioMobile({
  persons, 
  personsFromApi, 
  checkedSkillsCount, 
  setCheckedSkillsCount,
  currentMatchIdx,
  setCurrentMatchIdx,
  activeMatches,
  matchesToDisplay
}) {
  
  const haircutCheckded = false
  const skills = ["React JS", "CSS3", "ES6", "Nice Haircut", "REST", "SCRUM"]

  const handleClickOnSkill = (e) => {
    let clickedOn = e.target
    let allreadyChecked = clickedOn.classList.contains("checked")
    if (allreadyChecked) {
      clickedOn.classList.remove("checked")
      setCheckedSkillsCount(prev => prev - 1)
      alterPersonsArray(4)
    } else {
      clickedOn.classList.add("checked")
      setCheckedSkillsCount(prev => prev + 1)
      alterPersonsArray(-4)
    }
  }

  const handleCarousselLeftClick = () => {
    const resetToEnd = currentMatchIdx === 0
    const index = resetToEnd ? persons.length - 1 : currentMatchIdx - 1
    setCurrentMatchIdx(index)
  }

  const handleCarousselRightClick = () => {
    const resetIndex = currentMatchIdx === persons.length - 1;
    const index = resetIndex ? 0 : currentMatchIdx + 1;
    setCurrentMatchIdx(index);
  }

  const alterPersonsArray = (add) => {
    if (add < 0) {
      // remove i persons from persons
      for (let i = add; i < 0; i++) {
        persons.length > 1 && persons.shift()
      }
    } else {
      // push i persons from personsFromApi to persons
      for (let i = add; i > 0; i--) {
        checkedSkillsCount > 1 && checkedSkillsCount < 4 ? persons.unshift(personsFromApi[i + checkedSkillsCount * add]) : persons.unshift(personsFromApi[i])
      }
    }
  }

  return (
      <div className="landingpage-container">
      <header className="header">
        <div className="matches-container">

          <div className="matches__title">
            <p>Find your passionated</p>
            <h1>Frontend-Developer</h1>
          </div>
          
          {
            persons 
            && (
              <div className="matches__container">

                <p className="matches__header">
                  <span className="matches__header__count"> {persons.length} </span>
                  <span>Match{persons.length > 1 && 'es'}</span>
                </p>

                <div className="matches__caroussel">
                  <div className="left matches__caroussel__arrow" onClick={() => handleCarousselLeftClick("left")} > <i className="fas fa-angle-left" style={{ visibility: persons.length === 1 ? "hidden" : "visible" }} ></i> </div>
                  {
                    matchesToDisplay.map((person, index) => {

                      let picUrl = person.picture.thumbnail
                      let fullName = `${person.name.first} ${person.name.last}`

                      return (
                        <a href={person.me && "#aboutMe"}
                          className={`match matches__caroussel__${checkedSkillsCount > 3 || haircutCheckded
                            ? "middle"
                            : index === 0
                              ? 'left'
                              : (index === 1
                                ? 'middle'
                                : 'right')}-match`}
                          key={index}
                          id={index}
                          style={{ opacity: `${index === 1 || matchesToDisplay.length === 1 ? '1' : '.5'}`, left: `${matchesToDisplay.length === 1 && '20%'}` }}
                        >
                          <div className="pers__col-left">
                            {
                              <img src={picUrl} alt="" />
                            }
                          </div>

                          <div className="pers__col-right">
                            <div className="pers__col-right__nameBox">
                              <p className="pers__name">
                                {
                                  fullName
                                }
                              </p>
                            </div>
                            <p className="pers_availability">
                              {
                                fullName !== "José Guerrero Vazquez"
                                  ? (<span className="pers__availability notAvailable">not available</span>)
                                  : (<span className="pers__availability available">available from Aug 2020</span>)
                              }
                            </p>
                          </div>
                        </a>
                      )
                    })
                  }

                  <div className="right matches__caroussel__arrow" onClick={() => handleCarousselRightClick("right")} > <i className="fas fa-angle-right" style={{ visibility: persons.length === 1 ? "hidden" : "visible" }} ></i> </div>
                </div>
              </div>
            )
          }

        </div>
        <div className="matches__form">
          <p className="matches__form__title">Filter availabe Developers by skills:</p>
          <div className="matches__form__skills">
            {
              skills.map((skill, index) => {
                return (
                  <div className="form_skill" key={index} id={skill} onClick={e => handleClickOnSkill(e)}>{skill}</div>
                )
              })
            }

          </div>
        </div>
      </header>

      <main>
        <footer className="matches__footer">
          <div pan >..lets do this!</div>
          <div className="cta-downArrow">
            <a href="#aboutMe"><i class="fas fa-angle-double-down"></i></a>
          </div>
        </footer>
      </main>

    </div >
  )
}
