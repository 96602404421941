import React, { useEffect, useState } from 'react'
import "./AboutMe.css"

import myAvatar from "./img/profilpic-1.png"
import myKanban from "./img/mykanban.gif"

export default function AboutMe(props) {

  const [readingMode, setReadingMode] = useState(false)
  const {skillsList} = props 

  const toggleShowContent = (e) => {
    setReadingMode(true)

    let targetContent = e.target.nextSibling
    targetContent.classList.contains("hideMe")
      ? targetContent.classList.remove("hideMe")
      : targetContent.classList.add("hideMe")

    let allContent = document.querySelectorAll(".section__content")

    for (let i = 0; i < allContent.length; i++) {

      if (allContent[i].id !== targetContent.id) {
        !allContent[i].classList.contains("hideMe")
          && allContent[i].classList.add("hideMe")
      } else if (allContent[i].id === targetContent.id && targetContent.classList.contains("hideMe")) {
        setReadingMode(false)
      }
    }


  }

  useEffect(() => {

    const titles = document.querySelectorAll(".section__title")
    for (let i = 0; i < titles.length; i++) {
      titles[i].addEventListener("click", e => toggleShowContent(e));
    }
  }, [])

  return (
    <div className="aboutMe-container" id="aboutMe">

      <div className="aboutMe__header">
        <img src={myAvatar} alt="Avatar" />
        <span>Hi there!<br /> Nice to meet you!</span>
      </div>

      <div className="aboutMe__main">
        <div className="aboutMe__main__section">
          <div className="section__intro" style={{ display: readingMode ? "none" : "unset" }}>My Name is</div>
          <div className="section__title">José Guerrero Vazquez ↓<i class="fas fa-chevron-circle-down"></i></div>
          <div className="section__content hideMe" id="name">
            <span className="hideText">
              <span className="section__content__span">
                After military Service i re-educated to <strong>Technical Assistant for Computer Science</strong>.
              </span>
              <span className="section__content__span">
                While i worked as <strong>Database- and Front-End Developer</strong> i kept enganged with the Jägertruppe as a Military Reservist.
              </span>
              <span className="section__content__span">
                Meanwhile i support the Developers Team at <strong>Cyber Innovation Room</strong> in Bonn, Germany
              </span>
            </span>
          </div>
        </div>

        <div className="aboutMe__main__section">
          <div className="section__intro" style={{ display: readingMode ? "none" : "unset" }}>I work as a</div>
          <div className="section__title">Front-End Developer ↓<i class="fas fa-chevron-circle-down"></i></div>
          <div className="section__content hideMe" id="work">
            <span className="hideText">
              <span className="section__content__span">
                After apprenticing as a Technical Assistent for Computer Science my first job was planing and programming database
                interfeaces with MySQLi and PHP in a Wen-Relaunch Projekt.
              </span>
              <span className="section__content__span">
                Today, agile development is my preferred method of software development. I seek to improve my skills continiously. 
              </span>


            </span>
          </div>
        </div>

        <div className="aboutMe__main__section">
          <div className="section__intro" style={{ display: readingMode ? "none" : "unset" }}>in a pilot project called </div>
          <div className="section__title">Cyber Innovation Room ↓<i class="fas fa-chevron-circle-down"></i></div>
          <div className="section__content hideMe" id="CIR">
            <span className="hideText">
              <span className="section__content__span">
                The <strong>Cyber Innovation Room</strong> is a pilot project in the Germany Army.
                One of the main tasks is to build a bridge between innovative startups and the Bundeswehr.
              </span>
              <span className="section__content__span">
                I was given the chance to support the Developer Team for a total of 11 months, until July 31.
                Here i am learning a lot about <strong>React JS, RESTful APIs, Sharepoint, agile development with SCRUM and Kanban</strong> and much more..
              </span>
            </span>
          </div>
        </div>

        <div className="aboutMe__main__section">
          <div className="section__intro" style={{ display: readingMode ? "none" : "unset" }}>where i use my</div>
          <div className="section__title">Skills & capabilities ↓<i class="fas fa-chevron-circle-down"></i></div>
          <div className="section__content hideMe" id="skills">
            <span className="hideText">
              <span className="section__content__span">
              I consider my professional strengths to lie in being eager to learn. 
              Even though i enjoy most solving logical problems with <strong>JavaScript</strong>, there are many other things sparking my interest.
              </span>
              <div className="section__content__skills">
                {
                  skillsList.map((skill, index) => {
                    let skillLevel = skill.level + Math.floor(Math.random() * Math.floor(25))
                    let r = Math.floor(Math.random() * Math.floor(155))
                    let g = Math.floor(Math.random() * Math.floor(155))
                    let b = Math.floor(Math.random() * Math.floor(155))
                    let randomColor = `rgb(${r},${g},${b})`
                    return(
                      <span key={index} style={{fontSize: skillLevel, color: randomColor}}>{skill.skill} </span>
                    )
                  })
                }
              </div>
            </span>
          </div>
        </div>

        <div className="aboutMe__main__section">
          <div className="section__intro" style={{ display: readingMode ? "none" : "unset" }}>in different</div>
          <div className="section__title">Projects ↓<i class="fas fa-chevron-circle-down"></i></div>
          <div className="section__content hideMe" id="projets">
            <span className="hideText">
            <span className="section__content__span">
            Lately i built a <strong>digital KanBan Board </strong> prototype with React JS and firebase db to enable instant updates.
            The idea was to keep up the regular Team Kanban Sessions during the Corona(Covid-19) pandemic.
            
              </span>
              
              <img src={myKanban} alt="Digital KanBan Prototype" id="kanbanPic"/>
            </span>
          </div>
        </div>

      </div>
      
      <div className="cta-downArrow aboutMe">
        <a href="#getInTouch"><i class="fas fa-angle-double-down"></i></a>
      </div>
    </div>
  )
}