import React, { useState, useEffect } from 'react';
import './App.css';

import AboutMe from "./AboutMe"
import GetInTouch from "./GetInTouch"

import myAvatar from "./img/profilpic-1.png"
import MyPortfolioDesktop from './MyPortfolioDesktop';
import MyPortfolioMobile from './MyPortfolioMobile';

function App() {

  const [persons, setPersons] = useState([])
  const [loading, setLoading] = useState(true)
  const [personsFromApi, setPersonsFromApi] = useState([])
  const [checkedSkillsCount, setCheckedSkillsCount] = useState(0)
  const [currentMatchIdx, setCurrentMatchIdx] = useState(0);

  const skillSet = [
    {skill: "CSS3", level: 8},
    {skill: "HTML5", level: 8},
    {skill: "ES2015", level: 9},
    {skill: "PHP", level: 3},
    {skill: "MySQL", level: 3},
    {skill: "ReactJS", level: 9},
    {skill: "git", level: 5},
    {skill: "Typescript", level: 6},
    {skill: "UI/UX", level: 7},
    {skill: "node.js", level: 4},
    // {skill: "python", level: 1},
  ]
  const getPersonsAmount = 16

  //Get acutal viewport height to solve adressebar problem on mobile devices
  //source: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  const getRealViewportHeight = () => {
    // First get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  
  const getPersonsLength = React.useCallback(() => {
    return persons.length
  }, [persons])

  useEffect(() => {
    fetch(`https://randomuser.me/api/?nat=us&results=${getPersonsAmount}&inc=name,picture,nat&noinfo`)
      .then(res => res.json())
      .then(persons => {
        setPersonsFromApi(persons.results)
        setPersons([...persons.results, { name: { first: "José", last: "Guerrero Vazquez" }, picture: { thumbnail: myAvatar }, me: true }])
      })
      .then(() => setLoading(false))
  },[])

  useEffect(() => {
    getPersonsLength() > 1 && (document.getElementById("0").style.left = "-45%")
  }, [checkedSkillsCount])

  useEffect(() => {
    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    getRealViewportHeight()    
  }, [])

  const activeMatches = persons.slice(
    currentMatchIdx,
    currentMatchIdx + 3
  )
  //matchesToDisplay wird immer genau 3 Elemente beinhalten
  const matchesToDisplay = persons.length < 4
    ?
    persons
    : activeMatches.length < 3
      ? [...activeMatches, ...persons.slice(0, 3 - activeMatches.length)]
      : activeMatches

  return (
    <div className="all-container">

      {!loading && (
        <div className="mobile">
          <MyPortfolioMobile 
            loading={loading} 
            persons={persons} 
            personsFromApi={personsFromApi}
            checkedSkillsCount={checkedSkillsCount}
            setCheckedSkillsCount={setCheckedSkillsCount}
            activeMatches={activeMatches}
            matchesToDisplay={matchesToDisplay}
            setCurrentMatchIdx={setCurrentMatchIdx}
            currentMatchIdx={currentMatchIdx}
          />
          <AboutMe skillsList = {skillSet} />
          <GetInTouch />
        </div>
      )}
      

      <div className="desktop">
        <MyPortfolioDesktop skillsList = {skillSet} />
      </div>

    </div >
  );
}

export default App;
